.headroom {
    will-change: transform;
    background-color: #fff;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--top{
    padding-top: .5rem;
	padding-bottom: .5rem;
    background-color: #fff !important;
    a{
        color: #000 !important;
    }
    // .logo-dark{
    //     display: none;
    // }
    // .logo-white{
    //     display: block;
    // }
    .navbar-toggler-icon{
        background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0,0,0,0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
    }
    .logo-white{
        display: none;
    }
    .logo-dark{
        display: block;
    }
}

.headroom--not-top {
	padding-top: .2rem;
	padding-bottom: .2rem;
    // background-color: theme-color("default") !important;
    background-color: #fff !important;
    box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
    a{
        color: #000 !important;
    }
    .navbar-toggler-icon{
        background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0,0,0,0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
    }
    .logo-white{
        display: none;
    }
    .logo-dark{
        display: block;
    }
}
